/* global USE_SERVICE_WORKER */
import './webpack-imports';
import './sentry';
import './polyfills';
import indexSlider from './inits/index-slider';
import achievementsSlider from './inits/achievements-slider';
import marquee from './inits/marquee';
import anchors from './inits/anchors';
import popups from './inits/lit-popup';
import inputFocus from './inits/input-focus';
import ajaxForms from './inits/ajax-forms';
import reveal from './inits/reveal';
import mask from './inits/mask';
import './inits/init-sticky-header';

import smoothscroll from 'smoothscroll-polyfill';
import headerObserver from './components/header-theme-observer';

import sw from './sw';
import { registerCustomElements } from './custom-elements';

document.addEventListener('DOMContentLoaded', () => {
    // Forces repaint, use when really needed.
    // document.documentElement.classList.add('js-ready');
    registerCustomElements();
    smoothscroll.polyfill();
    indexSlider.init();
    achievementsSlider.init();
    anchors.init();
    marquee.init();
    popups.init();
    inputFocus.init();
    ajaxForms.init();
    headerObserver.init();
    reveal.init();
    mask.init();

    // Code here
});

// Service Worker
if (USE_SERVICE_WORKER) {
    window.addEventListener('load', () => sw.register());
} else {
    sw.unregister();
}
