const header = document.querySelector('.js-header');
const commonRootMargin = '-10% 0% -90% 0%';
const callback = entries => {
    entries.forEach(entry => {
        if (header) {
            if (entry.isIntersecting) {
                entry.target.dataset.headerTheme == 'dark' ? header.classList.add('dark-theme') : header.classList.remove('dark-theme');
            }
        }
    });
};

const map = new WeakMap();

const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init() {
    const elements = Array.from(document.querySelectorAll('[data-header-theme]'));

    elements.forEach(el => {
        const rootMargin = el.dataset.rootMargin || commonRootMargin;
        const observer =
            rootMargin === commonRootMargin
                ? commonObserver
                : new IntersectionObserver(callback, { rootMargin, threshold: [0, 1] });

        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy() {
    const elements = Array.from(document.querySelectorAll('[data-header-theme="dark"]'));

    elements.forEach(el => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
        }
    });
}

export default { init, destroy };
