import KeenSlider from 'keen-slider';

let slider;
let interval = 0;
const wrapper = document.querySelector('.achievements');

function autoplay(run) {
    clearInterval(interval)
    interval = setInterval(() => {
      if (run && slider) {
        slider.next()
      }
    }, 8000)
}

function updateClasses(instance) {
    let prev = document.querySelector('.js-achivements-prev');
    let next = document.querySelector('.js-achivements-next');
    slide === 0
    ? prev.classList.add("arrow--disabled")
    : prev.classList.remove("arrow--disabled")
  slide === instance.details().size - 1
    ? next.classList.add("arrow--disabled")
    : next.classList.remove("arrow--disabled")
    let curIndex = document.querySelector('.js-slider-index--cur');
    var slide = instance.details().relativeSlide;
    var dots = wrapper.querySelectorAll(".dot")
    dots.forEach(function (dot, idx) {
      setTimeout(()=> {
        idx === slide
        ? dot.classList.add("dot--active")
        : dot.classList.remove("dot--active")
        curIndex.innerHTML = `0${slide+1}`;
      }, 10)
    })
}


function init(container = document) {
    const sliderEl = container.querySelector('.js-achievements-slider');
    slider = new KeenSlider(sliderEl, {
        loop: true,
        duration: 1000,
        spacing: 100,
        dragStart: () => {
            autoplay(false)
        },
        dragEnd: () => {
            autoplay(true)
        },
        created: function (instance) {
            let prev = document.querySelector('.js-achivements-prev');
            let next = document.querySelector('.js-achivements-next');
            prev.addEventListener("click", function () {
                instance.prev()
            });
            next.addEventListener("click", function () {
                instance.next()
            });

            let dots_wrapper = document.querySelector('.js-achivements-slider__dots');
            let slides = sliderEl.querySelectorAll(".keen-slider__slide");
            let curIndex = document.createElement('div');
            curIndex.classList.add('slider-index');
            curIndex.classList.add('slider-index--cur');
            curIndex.classList.add('js-slider-index--cur');
            curIndex.innerHTML = '01';
            dots_wrapper.appendChild(curIndex);
            slides.forEach(function (t, idx) {
                let dot = document.createElement('div');
                dot.classList.add("dot");
                dot.classList.add('dot__achievement');
                dots_wrapper.appendChild(dot)
                dot.addEventListener("click", function () {
                    instance.moveToSlide(idx)
                })
            })
            let totalIndex = document.createElement('div');
            totalIndex.classList.add('slider-index');
            totalIndex.classList.add('slider-index--total');
            totalIndex.classList.add('js-slider-index--total');
            totalIndex.innerHTML = `0${slides.length}`;
            dots_wrapper.appendChild(totalIndex);
            updateClasses(instance)
        },
        slideChanged(instance) {
            updateClasses(instance)
        },
    });

    autoplay(true);
}

function destroy(container = document) {
    slider.destroy();
}

export default {init, destroy}