import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';

const SELECTOR = '.js-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();

const showMessage = (container:Element, form: HTMLFormElement, message = [{name: 'name',error: 'Login not found!'}, {name: 'email',error: 'Password is wrong!'}]) => {
    if (message) {
        message.forEach(msg => {
            const input = form?.querySelector(`input[name=${msg.name}]`);
            let messageEl = input?.parentElement?.querySelector('.app-message');
            if (messageEl) {
                messageEl.textContent = msg.error;
            }
        })
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    if (messagesContainer) {
        messagesContainer.classList.remove('form-messages--hidden');
    }
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector('input.js-form__check-val') as HTMLInputElement | null;
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];

    if (forms.length > 0) {
        const [{ default: createFormSender }, { default: createValidator, clearMessages }] = await Promise.all([
            fetchAjaxFormSender(),
            fetchValidator(),
        ]);

        forms.forEach((form) => {
            const validator = createValidator(form);
            const sender = createFormSender(form, {
                shouldClearInputs: true,
                onBeforeSend: () => {
                    clearAntispamInput(form);
                    form.classList.add('form--loading');
                },
                onSuccess: ({ success, message }) => {
                    setTimeout(() => {
                        form.classList.remove('form--loading');
                    }, 600)
                },
                onError: () => {
                    form.classList.remove('form--loading');
                },
                onComplete: () => {
                    clearMessages(form);
                    showFormMessages(form);
                },
            });
            map.set(form, sender);

            form.addEventListener('submit', (event) => {
                event.preventDefault();
                const isFormValid = validator.validate();
                if (isFormValid) {
                    sender.send();
                }
            });
        });
    }
}

function destroy(container: Element | Document = document) {
    //TODO
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;