import IMask from 'imask';

function init() {
    const tels = Array.from(document.querySelectorAll('input[data-mask="tel"]'));

    tels?.map(tel => {
        const imask = IMask(tel, { mask: '+{7} (000) 000-00-00' });
    });
}

export default { init }