import LitPopup from 'lit-popup';
import { lockBodyScroll, unlockBodyScroll } from '../utils/lock-body-scroll';

const map = new Map<Element, LitPopup>();

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll('[data-lit-popup]')) as HTMLElement[];
    elements.forEach((el) => {
        const name = el.dataset.litPopup;
        let wasBodyLocked = false;

        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }

        const lockScroll = () => {
            wasBodyLocked = document.body.classList.contains('no-scroll');

            if (!wasBodyLocked) {
                lockBodyScroll();
            }
        };

        const unlockScroll = () => {
            if (!wasBodyLocked) {
                unlockBodyScroll();
            }
        };
        let opener = document.querySelector(`[data-lit-popup-open="${name}"]`);
        const popup = new LitPopup(name, {
            onOpen: (e) => {
                lockScroll();
            },
            onOpenComplete: () => {
                setTimeout(()=> {
                    opener.removeAttribute('data-lit-popup-open');
                    opener.setAttribute('data-lit-popup-close', name);
                }, 1)
            },
            onClose: () => {
            },
            onCloseComplete: () => {
                unlockScroll();
                opener.removeAttribute('data-lit-popup-close');
                opener.setAttribute('data-lit-popup-open', name);
            },
        });

        map.set(el, popup);
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll('[data-lit-popup]')) as HTMLElement[];

    elements.forEach((el) => {
        const popup = map.get(el);

        if (popup) {
            popup.destroy();
            map.delete(el);
        }
    });
}

function getInstanceByElement(element?: Element | null) {
    return element ? map.get(element) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
