export function lockBodyScroll() {
    document.body.classList.add('no-scroll');
    if (window.locoInstance) {
        window.locoInstance.stop();
    }
}

export function unlockBodyScroll() {
    document.body.classList.remove('no-scroll');
    if (window.locoInstance && window.locoInstance.start) {
        window.locoInstance.start();
    }
}
