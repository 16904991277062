import KeenSlider from 'keen-slider';

let slider;
let interval = 0;
const wrapper = document.querySelector('.index');

function autoplay(run) {
    clearInterval(interval)
    interval = setInterval(() => {
      if (run && slider) {
        slider.next()
      }
    }, 8000)
}

function updateClasses(instance) {
    var slide = instance.details().relativeSlide;
    var dots = wrapper.querySelectorAll(".dot")
    dots.forEach(function (dot, idx) {
        setTimeout(()=> {
            idx === slide
            ? dot.classList.add("dot--active")
            : dot.classList.remove("dot--active")
          }, 10)
    })
}

function init(container = document) {
    const sliderEl = container.querySelector('.js-index-slider');
    slider = new KeenSlider(sliderEl, {
        loop: true,
        duration: 1000,
        dragStart: () => {
            autoplay(false)
        },
        dragEnd: () => {
            autoplay(true)
        },
        created: function (instance) {
            let dots_wrapper = document.querySelector('.js-index-slider__dots');
            let slides = sliderEl.querySelectorAll(".keen-slider__slide");

            slides.forEach(function (t, idx) {
                let dot = document.createElement('div');
                dot.innerHTML = `
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="6" fill="white" class="dot-circle-inner"/>
                        <circle cx="9" cy="9" r="8.5"  stroke="white" class="dot-circle-outer"/>
                    </svg>
                `;

                dot.classList.add("dot")
                dots_wrapper.appendChild(dot)
                dot.addEventListener("click", function () {
                    instance.moveToSlide(idx)
                })
            })
            updateClasses(instance)
        },
        slideChanged(instance) {
            updateClasses(instance)
        },
    });

    autoplay(true);
}

function destroy(container = document) {
    slider.destroy();
}

export default {init, destroy}