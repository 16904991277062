import {findAncestor} from '../utils/find-ancestor';

let anchors = [];
function onClick(e) {
    e.preventDefault();
    let link = e.target;
    if (!e.target.classList.contains('js-anchor')) {
        link = findAncestor(e.target, 'js-anchor');
    }
    let target = document.querySelector(link.hash);
    target.scrollIntoView({behavior: 'smooth'})
}

function init(container = document) {
    anchors = Array.from(container.querySelectorAll('.js-anchor'));
    anchors.forEach(anchor => anchor.addEventListener('click', onClick));
}

function destroy() {
    anchors.forEach(anchor => anchor.removeEventListener('click', onClick));
}

export default {init, destroy}