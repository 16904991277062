import gsap from 'gsap';

const wrapper = document.querySelector('.js-clients-wrap');
let clients = wrapper.querySelector('.clients');

function init() {
    let clone = clients.cloneNode(true);
    wrapper.appendChild(clone);
    console.log(clients.clientWidth)
    gsap.to(wrapper, {x: -clients.clientWidth * 1.5, ease: "none", repeat: -1, duration: 20, yoyo: true});
}

export default {init}